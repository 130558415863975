import VueAccessor from "@/assets/plugins/mps-common/app/VueAccessor";
import {getDuration, toFormattedDuration} from "@/assets/plugins/mps-common/time/duration-util";
import {isThresholdExceededOf} from "@/assets/plugins/mps-common/time/duration-common";
import {translationManager} from "@/assets/plugins/mps-common/translation/translation-manager";

export class Device extends VueAccessor {

    _item;

    constructor(item) {
        super();
        this._item = item;
    }

    get raw() {
        return this._item;
    }

    get formattedCollectedDate() {
        return toFormattedDuration(this._item.uptDatetime);
    }

    get formattedConnectedPrinterCount() {
        return this._item.connectedPrinterCount;
    }

    get isInHeadOffice() {
        return this._item.accountId === 0;
    }

    get isThresholdExceeded() {
        return isThresholdExceededOf(this._item.uptDatetime);
    }

    get statusName() {
        if (!this.isStatusDown) {
            return translationManager.translate("접속중");
        }

        return translationManager.translate("연결끊어짐")
    }

    get isStatusDown() {
        return this._item.status !== "1";
    }

    get deviceTypeName() {
        if (this._item.deviceType === "PC") {
            return translationManager.translate("PC에이전트");
        } else if (this._item.deviceType === "DV") {
            return translationManager.translate("콘솔");
        }

        return ""
    }
}
