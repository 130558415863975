<template>
  <v-card
    class="mx-auto mt-0 boder-bottom"
    width="100%"
    flat
    style="border-radius: 0px;"
  >
    <!--    :color="{'red': item.isReservationIsOver }"-->
    <v-list-item
      v-ripple="false"
      three-line
      style="padding: 14px 18px !important;"
      @click="onDetailClicked(item)"
    >
      <v-list-item-content class="pa-0">
        <v-list-item-title class="d-flex justify-space-between">
          <!-- 업체명 -->
          <div
            class="font__17 black--text font-weight-bold text-left"
            style="white-space: pre-wrap !important;"
          >
            <template v-if="!!item.raw.accountId">
              <span>{{ item.raw.accountName }}</span>
              <span>{{ item.raw.accountId }}]</span>
            </template>
            <template v-else>
              <span>{{ '본사' | translate }}</span>
            </template>
          </div>

          <span
            class="font__16"
            :class="{'color__red': item.isThresholdExceeded, 'color__blue': !item.isThresholdExceeded}"
          >
            {{ item.formattedCollectedDate }}
          </span>
        </v-list-item-title>
        <v-list-item-subtitle class="font__14 black--text">
          <v-row
            justify="start"
            class="pa-0 ma-0"
          >
            <div>
              <span>{{ item.raw.serialNo | serial }}</span>
            </div>
          </v-row>
        </v-list-item-subtitle>
        <v-list-item-subtitle class="font__14 color__666 text-left">
          <v-row
            justify="space-between"
            class="pa-0 ma-0"
          >
            <span>{{ '등록된 프린터' | translate }} : {{ item.formattedConnectedPrinterCount }}{{ '대' | translate }}</span>
            <div>
              <span>{{ item.deviceTypeName }}</span>
            </div>
          </v-row>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
    export default {
        name: "DeviceItemCard",
        components: {},
        props: {
            item: {type: Object, default: undefined},
            options: {
                type: Object, default: () => {
                }
            },
        },
        computed: {},
        methods: {
            /**
             * 상세 버튼 클릭시
             * @param work
             */
            onDetailClicked(item) {
                this.routerManager.push({
                    name: "DeviceDetail",
                    params: {tnDeviceId: item.raw.tnDeviceId, accountId: item.raw.accountId}
                });
            },

        }
    }
</script>
