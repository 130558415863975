<script>
    export default {
        name: "DetailUtil",
        computed: {
            getFormInfo() {
                return this.formInfo;
            },
        },
        methods: {
            getOptionByKey(key) {
                return this.getFormInfo.find(info => info.key === key);
            },

            updateOptionByKey(key, callback) {
                const info = this.getOptionByKey(key);

                if (!info) return;
                this.$set(info, 'options', callback());
            },

            updatePropsDataByKey(key, callback) {
                const info = this.getOptionByKey(key);

                if (!info) return;
                this.$set(info, 'propsData', callback());
            },
        }
    }
</script>