<template>
  <div
    v-if="displayable"
    class="d-flex justify-space-between align-center mt-0 mx-0 px__18"
    style="height: 32px; background: #f4f4f4;"
  >
    <span class="font__13 color__333 text--color">
      {{ propsDataSafe.title }}
    </span>
  </div>
</template>

<script>
    export default {
        name: "GroupHeader",
        props: {
            propsData: { type: Object, default: () => {} },
        },
        computed: {
            propsDataSafe() {
                return this.propsData || {};
            },
            displayable() {
                if (!this.propsDataSafe.title) return false;
                return true;
            }
        },
    }
</script>

<style scoped>
  .text--color {
    color: #2196f3;
  }
</style>