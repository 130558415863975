<script>
    export default {
        /**
         * 편집모드로 접근시 BackGuard(뒤로가기 방어)를 설정해준다.
         */
        name: "BackGuardEditModeInterface",
        data() {
            return {
                editMode: false,
            }
        },
        watch: {
            editMode() {
                if (this.editMode) { // 편집모드 전환시 백가드 설정
                    this.pushEditModeBackGuard();
                }
            },
        },
        methods: {
            /**
             * BackGuard를 설정한다.
             */
            pushEditModeBackGuard() {
                this.backGuard.push(() => {
                    this.tryToCloseEditMode(false);
                });
            },

            /**
             * 편집모드에서 나올시 호출되는 메소드 입니다.
             *
             * 뒤로가기시에도 이 메소드가 호출됩니다.
             */
            tryToCloseEditMode(closeButtonClicked) {
                // console.warn("Implement tryToCloseEditMode method to confiure BackGuard!");
                const form = this.getFormRef();
                if (!!form && form.isDirty) {
                    this.$dialog()
                        .message(this.$translate('편집중인 정보가 저장되지 않습니다.'))
                        .buttonNegative(this.$translate('취소'), () => {
                            // 뒤로가기 되었을 때만 재설정합니다.
                            if (!closeButtonClicked) this.pushEditModeBackGuard();
                        })
                        .buttonPositive(this.$translate('닫기'), () => {
                            form.restore();
                            this.setEditMode(false);
                            // 닫기 버튼 클릭시에만 pop 합니다.
                            if (closeButtonClicked) this.backGuard.pop(); // 뷰 모드로 전환시 제거합니다.
                        })
                        .build().show()
                } else {
                    this.setEditMode(false);
                    // 닫기 버튼 클릭시에만 pop 합니다.
                    if (closeButtonClicked) this.backGuard.pop(); // 뷰 모드로 전환시 제거합니다.
                }
            },

            /**
             * 편집모드 들어올때와 편집모드에서 나갈때를 정의합니다.
             */
            setEditMode(enabled) {
                console.warn("Implement setEditMode method to confiure BackGuard!");
            },

            /**
             * 편집 폼의 ref를 반환해야 합니다.
             */
            getFormRef() {
                return this.$refs ? this.$refs.form : null;
            },
        },
    }
</script>