<template>
  <div class="overflow-y-auto overflow-x-hidden">
    <my-tool-bar
      :action-mode="false"
      :title="title"
    >
      <template #buttons>
        <toolbar-save-action
          @save="onSave"
          @refresh="onRefreshClicked"
        />
      </template>
    </my-tool-bar>

    <div style="position: relative; top: 56px; height: calc(100% - 56px);">
      <m-form
        ref="form"
        :edit="true"
        :form-data.sync="formData"
        :form-info="formInfo"
        class="overflow-y-auto overflow-x-hidden"
        form-provider="mps"
      />
    </div>
  </div>
</template>

<script>
    import WindowComponent from "@/assets/plugins/mps-window-mobile/WindowComponent";
    import MyToolBar from "@/components/app/toolbar/MyToolBar";
    import {MAXLENGTH, PATH} from "@/constants";
    import VuexProps from "@/framework/vuex/VuexProps";
    import GroupHeader from "@/assets/plugins/mps-form/providers/components/group/GroupHeader";
    import ToolbarSaveAction from "@/components/app/toolbar/ToolbarSaveAction";
    import MForm from "@/assets/plugins/mps-form/MForm";
    import DetailUtil from "@/components/mixins/DetailUtil";

    const infoKeys = {
        account: "account",
        businessNo: "businessNo",
        serialNo: "serialNo",
        installLocation: "installLocation",
        memo: "memo"
    };

    export default {
        name: "DeviceRegister",
        components: {MForm, ToolbarSaveAction, MyToolBar},
        extends: WindowComponent,
        mixins: [VuexProps, DetailUtil],
        data() {
            return {
                formData: undefined,
                formInfo: [
                    {
                        component: GroupHeader,
                        propsData: {
                            title: this.$translate("장치 정보"),
                        },
                    },
                    {
                        mutable: true,
                        title: this.$translate('거래처명'),
                        key: infoKeys.account,
                        read: (inEditMode, data) => null,
                        write: (data, v) => {
                            if (!v) return;
                            data.accountId = v.accountId;
                            data.accountName = v.accountName;
                            data.businessNo = v.businessNo;
                        },
                        formReadType: this.$mps.form.types.ACCOUNT_PICKER,
                        formWriteType: this.$mps.form.types.ACCOUNT_PICKER,
                        options: {},
                        rules: [
                            (data) => !!data.accountId || this.$translate('거래처를 선택해 주세요'),
                        ],
                    },
                    {
                        mutable: false,
                        title: this.$translate('사업자번호'),
                        key: infoKeys.businessNo,
                        formReadType: this.$mps.form.types.TEXTAREA,
                        formWriteType: this.$mps.form.types.TEXTAREA,
                    },
                    {
                        mutable: true,
                        title: this.$translate('시리얼번호'),
                        key: infoKeys.serialNo,
                        formReadType: this.$mps.form.types.VUE_THE_MASK,
                        formWriteType: this.$mps.form.types.VUE_THE_MASK,
                        rules: [
                            (data) => !!data.serialNo || this.$translate('시리얼번호를 입력해 주세요'),
                            (data) => data.serialNo.length == 12 || this.$translate('시리얼번호는 12자리 입니다.')
                        ],
                    },
                    {
                        mutable: true,
                        title: this.$translate('설치위치'),
                        key: infoKeys.installLocation,
                        formReadType: this.$mps.form.types.TEXTAREA,
                        formWriteType: this.$mps.form.types.TEXTAREA,
                        options: {
                            maxlength: MAXLENGTH.DEVICE.INSTALL_LOCATION,
                        },
                        fullWidthLabel: true,
                    },
                    {
                        mutable: true,
                        title: this.$translate('비고'),
                        key: infoKeys.memo,
                        formReadType: this.$mps.form.types.TEXTAREA,
                        formWriteType: this.$mps.form.types.TEXTAREA,
                        options: {
                            maxlength: MAXLENGTH.DEVICE.MEMO,
                        },
                        fullWidthLabel: true,
                    },
                ],
            }
        },
        computed: {
            props() {
                return this.GET_PROPS("DeviceRegister");
            },
        },
        watch: {
            formData: {
                deep: true,
                handler: function () {
                    this.updateOptions();
                }
            },
        },
        mounted() {
        },
        unmounted() {
        },
        created() {
            this.initFormData();
            this.updateOptions();
        },
        methods: {
            updateOptions() {
                // 거래처 피커
                this.updateOptionByKey(infoKeys.account, () => {
                    return {
                        accountId: this.formData.accountId,
                        accountName: this.formData.accountName
                    }
                });
            },

            async requestSave(formData) {
                try {
                    const response = await this.$requestWithOverlay(PATH.DEVICE.REGISTER)
                        .setObject(formData)
                        .enqueue();
                    this.$snackbar(this.$translate("장치가 등록되었습니다."));
                    this.initFormData();
                    this.$router.go(-1);
                } catch (e) {
                    console.log("e.code:" + JSON.stringify(e));
                    if (e.data.code === -9100) {
                        return this.$snackbarError(this.$translate("장치 시리얼 번호가 중복되었습니다."));
                    }

                    this.$snackbarError(this.$translate("서버에서 에러가 발생하였습니다."));
                }
            },

            async onSave() {
                if (!await this.$refs.form.validate()) return;
                this.requestSave({
                    ...this.formData,
                    serialNo: this.formData.serialNo.replace(/-/g, ''),
                });
            },

            initFormData() {
                this.formData = {
                    accountId: null,
                    accountName: null,
                    businessNo: null,
                    serialNo: null,
                    installLocation: null,
                    memo: null,
                };
            },

            onRefreshClicked() {
                this.$dialog()
                    .message(this.$translate('편집중인 정보를 초기화합니다.'))
                    .buttonNegative(this.$translate('취소'))
                    .buttonPositive(this.$translate('초기화'), () => {
                        this.initFormData();
                    })
                    .build().show();
            },
        },
    }
</script>

<style scoped>

</style>
