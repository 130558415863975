<template>
  <v-layout :align-center="true">
    <!--닫기:취소 동작수행-->
    <v-btn
      v-if="!expanded"
      icon
      @click="onCloseClicked"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-btn
      v-else
      icon
      @click="onClickBack"
    >
      <!--            뒤로가기 버튼 -->
      <!--<v-icon>mdi-arrow-left</v-icon>-->
      <img src="@/assets/images/main/svg/button__Previous.svg">
    </v-btn>
    <v-toolbar-title>
      {{ '수정' | translate }}
    </v-toolbar-title>
    <!--스페이서-->
    <v-spacer />
    <!--액션 버튼들-->
    <v-btn
      v-if="!expanded && deletable"
      icon
      @click="onDelete"
    >
      <img
        :src="getImgUrl('main/svg/trash.svg')"
        style="width: 24px;"
      >
    </v-btn>
    <v-btn
      v-if="!expanded"
      icon
      @click="onSave"
    >
      <!--<v-icon>mdi-content-save</v-icon>-->
      <img src="@/assets/images/main/svg/button_Save.svg">
    </v-btn>
  </v-layout>
</template>

<script>
    export default {
        name: "ToolbarModifyAction",
        props: {
            expanded: {
                type: Boolean,
                default: false,
            },
            value: {
                type: String,
                default: ''
            },
            deletable: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                onGuard: false,
            }
        },
        methods: {
            onCloseClicked() {
                this.$emit('close', true);
            },
            // 저장 버튼이 눌렸습니다.
            onSave() {
                if (this.onGuard) return;
                this.$emit('save');
            },

            // 삭제 버튼이 눌렸습니다.
            onDelete() {
                if (this.onGuard) return;
                this.$emit('delete');
            },

            onClickBack() {
                this.routerManager.back();
            },

        },
    }
</script>

<style scoped>

</style>
